import React, { useEffect, useState } from 'react'
import axios from 'axios'
import theme from '../../../../styles/theme'
import { BackButton, ButtonContainer, CustomButton, FormInputContainer, InputContainer, StyledCheckBox } from '../../../Collapse/stepped/style'
import { P2PLogo } from '../../style'
import { SubTitle, Title, WithoutPContainer } from '../style'
import { HrColor, Table, TableTd, TableTr } from './style'
import { toast } from 'react-toastify'
import MembershipData from '../../../../entities/MembershipData'
import { City } from '../../types'

type Step5Props = {
  membershipData: MembershipData
  setMembershipData: (membershipData: MembershipData) => void
  setStep: (step: number) => void
  setLoading: (loading: boolean) => void
  cities: City[]
  initP2P: (processUrl: any) => void
}

type StepErrors = {
  terms?: string
}

export default function Step5(props: Step5Props) {
  const { membershipData, setMembershipData, setStep, initP2P, cities } = props
  const [termAccepted, setTermAccepted] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(true)
  const [errors, setErrors] = useState<StepErrors>({})
  const [req, setReq] = useState()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {}, [membershipData])

  const validateForm = () => {
    let result: boolean = true
    let formErrors: StepErrors = {}

    if (termAccepted === false) {
      formErrors.terms = 'Es necesario que acepte los términos y condiciones.'
      result = false
    }
    setErrors(formErrors)
    toast(formErrors.terms)
    return result
  }

  const formatDate = (stringDate: string) => {
    let date = new Date(stringDate),
      month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear()

    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }
    return [day, month, year].join('-')
  }

  const getCityName = (id: number) => {
    let city = cities.find((city) => city.value == id)
    if (city) {
      return city.label
    }
    return ''
  }

  const submitForm = () => {
    if (validateForm()) {
      handleNextStep()
    }
  }

  const handleNextStep = () => {
    const { identification, paymentType, urlReference } = membershipData
    const params = {
      terms: termAccepted,
      idn: identification,
      reference: urlReference,
      paymentType: paymentType.charAt(0).toUpperCase() + paymentType.slice(1).toLowerCase(),
    }

    axios
      .post(`${process.env.API_URL}/generatePayment`, params)
      .then((response: any) => response.data)
      .then((data) => {
        setReq(data)
        setMembershipData({
          ...membershipData,
          terms: termAccepted,
          request: data,
        })
        initP2P(data.processUrl)
        setStep(6)
      })
      .catch((error: any) => {
        if (error.response.status == 409) {
          toast('Usted ya tiene una transacción en proceso.')
          return
        }
        toast('Ocurrió un error en la transacción.')
      })
  }

  const {
    name,
    lastName,
    identification,
    phone,
    email,
    paymentType,
    loading,
    birthday,
    mainStreet,
    secondaryStreet,
    numeration,
    city,
    facName,
    facLastName,
    reference,
    address,
    facEmail,
    facPhone,
    facIdentification,
    facIdentificationType,
  } = membershipData
  return (
    <>
      <Title>Verifica tus datos</Title>
      <SubTitle>Revisa que tus datos sean los correctos para continuar con el pago de tu membresía.</SubTitle>
      <SubTitle weight="bold" marginBottom="8px" fontSize="14px">
        Membresía
      </SubTitle>
      <Table>
        <tbody>
          <TableTr className="monthlyTable">
            <TableTd className="monthlyOpt" textAling="left">
              {paymentType.toLowerCase() === 'monthly' ? 'Mensual' : 'Anual'}
            </TableTd>
            <TableTd className="monthlyCost" textAling="right">
              {paymentType.toLowerCase() === 'monthly' ? '$5.50' : '$65.00'}
            </TableTd>
          </TableTr>
          <TableTr>
            <TableTd width="100%" textAling="right" colSpan={2}></TableTd>
          </TableTr>
        </tbody>
      </Table>
      <br />
      <SubTitle weight="bold" marginBottom="8px" fontSize="14px">
        Datos de contacto
      </SubTitle>
      <Table className="table">
        <tbody>
          <TableTr>
            <TableTd textAling="left">Número de identificación:</TableTd>
            <TableTd textAling="right">{identification}</TableTd>
          </TableTr>
          <TableTr>
            <TableTd textAling="left">Nombre:</TableTd>
            <TableTd textAling="right">{name}</TableTd>
          </TableTr>
          <TableTr className="lastnameDisplay" facIdentificationType={facIdentificationType}>
            <TableTd textAling="left">Apellido:</TableTd>
            <TableTd textAling="right">{lastName}</TableTd>
          </TableTr>
          <TableTr>
            <TableTd textAling="left">Teléfono de contacto:</TableTd>
            <TableTd textAling="right">{phone}</TableTd>
          </TableTr>
          <TableTr>
            <TableTd textAling="left">Correo electrónico:</TableTd>
            <TableTd textAling="right">{email}</TableTd>
          </TableTr>
          <TableTr>
            <TableTd textAling="left">Fecha de nacimiento:</TableTd>
            <TableTd textAling="right">{birthday ? formatDate(birthday.toString()) : ''}</TableTd>
          </TableTr>
          <TableTr>
            <TableTd width="100%" textAling="right" colSpan={2}>
              <BackButton color={theme.gold} withoutMargin={true} size="12px" onClick={() => setStep(2)}>
                Editar campo
              </BackButton>
            </TableTd>
          </TableTr>
        </tbody>
      </Table>
      <br />
      <SubTitle weight="bold" marginBottom="8px" fontSize="14px">
        Datos de emisión
      </SubTitle>
      <Table className="table">
        <tbody>
          <TableTr>
            <TableTd width="40%" textAling="left">
              Ciudad:
            </TableTd>
            <TableTd width="60%" textAling="right">
              {city ? getCityName(city) : ''}
            </TableTd>
          </TableTr>
          <TableTr>
            <TableTd width="40%" textAling="left">
              Calle principal:
            </TableTd>
            <TableTd width="60%" textAling="right">
              {mainStreet}
            </TableTd>
          </TableTr>
          <TableTr>
            <TableTd width="40%" textAling="left">
              Numeración:
            </TableTd>
            <TableTd width="60%" textAling="right">
              {numeration}
            </TableTd>
          </TableTr>
          <TableTr>
            <TableTd width="40%" textAling="left">
              Calle secundaria:
            </TableTd>
            <TableTd width="60%" textAling="right">
              {secondaryStreet}
            </TableTd>
          </TableTr>
          <TableTr>
            <TableTd width="40%" textAling="left">
              Referencia:
            </TableTd>
            <TableTd width="60%" textAling="right">
              {reference}
            </TableTd>
          </TableTr>
          <TableTr>
            <TableTd width="100%" textAling="right" colSpan={2}>
              <BackButton color={theme.gold} withoutMargin={true} size="12px" onClick={() => setStep(3)}>
                Editar campo
              </BackButton>
            </TableTd>
          </TableTr>
        </tbody>
      </Table>

      <br />
      <SubTitle weight="bold" marginBottom="8px" fontSize="14px">
        Datos de facturación
      </SubTitle>
      <Table className="table">
        <tbody>
          <TableTr>
            <TableTd textAling="left">Número de identificación:</TableTd>
            <TableTd textAling="right">{facIdentification}</TableTd>
          </TableTr>
          <TableTr>
            <TableTd textAling="left">{facIdentificationType === 'R' ? 'Razón social' : 'Nombre'}:</TableTd>
            <TableTd textAling="right">{facName}</TableTd>
          </TableTr>
          {facIdentificationType !== 'R' && (
            <TableTr>
              <TableTd textAling="left">Apellido:</TableTd>
              <TableTd textAling="right">{ facLastName }</TableTd>
            </TableTr>
          )}
          <TableTr>
            <TableTd textAling="left">Teléfono de contacto:</TableTd>
            <TableTd textAling="right">{facPhone}</TableTd>
          </TableTr>
          <TableTr>
            <TableTd textAling="left">Correo electrónico:</TableTd>
            <TableTd textAling="right">{facEmail}</TableTd>
          </TableTr>
          <TableTr>
            <TableTd width="40%" textAling="left">
              Dirección:
            </TableTd>
            <TableTd width="60%" textAling="right">
              {address}
            </TableTd>
          </TableTr>
        </tbody>
      </Table>

      <HrColor color={theme.gold} />
      <FormInputContainer>
        <InputContainer>
          <StyledCheckBox error={errors.terms !== undefined}>
            <a href="https://s3.amazonaws.com/grande-table/terminos_y_condiciones_grande_table.pdf" target="_blank">
              Acepta los Términos y Condiciones{' '}
            </a>
            <input
              type="checkbox"
              checked={termAccepted}
              onChange={() => {
                setTermAccepted(!termAccepted)
                setIsDisabledButton(!isDisabledButton)
              }}
            />
            <span className="checkmark" />
          </StyledCheckBox>
        </InputContainer>
      </FormInputContainer>

      <ButtonContainer>
        <CustomButton id="GRTB_CONT_PAY" className="GRTB_SLEC_MEMB" onClick={submitForm} isDisabledButton={isDisabledButton}>
          Pagar
        </CustomButton>
        <BackButton id="GRTB_MEMBR_P5_REG" onClick={() => setStep(4)}>REGRESAR</BackButton>
      </ButtonContainer>
      <WithoutPContainer className="col-lg-12 col-md-12 col-sm-12 alignMargin">
        <HrColor color={theme.gold} />
        <table>
          <tr>
            <td>
              <SubTitle className="fontSize12" withoutMargin={true} paddingBottom="0" marginTop="8px">
                Esta transacción se procesa con
              </SubTitle>
            </td>
            <td>
              <P2PLogo src="https://static.placetopay.com/placetopay-logo.svg" alt="P2PLogo" width="125px" />
            </td>
          </tr>
        </table>
      </WithoutPContainer>
    </>
  )
}